<template>
  <v-card>
    <section-header
      :isCollapsable="false"
      v-tooltip.top="filename"
      class="file-name"
      >{{ filename }}</section-header
    >
    <v-card-text style="text-align: center">
      <v-icon size="72">{{ cardIcon }}</v-icon>
    </v-card-text>
    <v-card-actions>
      <v-btn text color="primary accent-4" @click="reveal = true" outlined
        >More
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn @click="download" color="primary"
        >Download
        <v-icon right>mdi-download</v-icon>
      </v-btn>
    </v-card-actions>
    <v-expand-transition>
      <v-card v-if="reveal" class="transition-ease-in-out v-card--reveal">
        <section-header :isCollapsable="false"> Details </section-header>
        <v-card-text class="pb-2">
          <ul style="list-style: none; margin: 0; padding: 0">
            <li><b>File Name:</b> {{ filename }}</li>
            <li><b>File Size:</b> {{ size | formatSize }}</li>
            <li><b>Mime Type:</b> {{ mimetype }}</li>
            <li><b>Created:</b> {{ created }}</li>
          </ul>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="primary accent-4" @click="reveal = false" outlined
            >Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-expand-transition>
  </v-card>
</template>

<script>
import SectionHeader from "@admin/components/SectionHeader.vue";
import Vue from "vue";

export default {
  name: "EngagementFileCard",
  components: { SectionHeader },
  data() {
    return {
      cardIcon: "",
      reveal: false,
    };
  },
  filters: {
    formatSize(size) {
      const units = ["B", "KB", "MB", "GB", "TB"];
      let i = 0;
      while (size >= 1024 && i < units.length - 1) {
        size /= 1024;
        i++;
      }
      let output = `${size.toFixed(2)} ${units[i]}`;
      if (units[i] === "B") {
        output = `${size} ${units[i]}`;
      }

      return output;
    },
  },
  props: {
    id: {
      type: String,
    },
    created: {
      type: String,
    },
    downloadUrl: {
      type: String,
    },
    filename: {
      type: String,
    },
    mimetype: {
      type: String,
    },
    size: {
      type: Number,
    },
  },
  methods: {
    download() {
      Vue.axios
        .get(this.downloadUrl, {
          responseType: "blob",
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", this.filename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
    },
  },
  mounted() {
    let iconString = "";
    switch (this.mimetype) {
      case "text/plain":
        iconString = "mdi-file-document";
        break;
      case "application/pdf":
        iconString = "mdi-file-pdf-box";
        break;
      case "application/msword":
        iconString = "mdi-file-word";
        break;
      case "application/vnd.ms-excel":
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        iconString = "mdi-file-table";
        break;
      case "image/jpeg":
      case "image/png":
      case "image/gif":
      case "image/tiff":
      case "image/bmp":
      case "image/svg+xml":
        iconString = "mdi-file-image";
        break;
      default:
        iconString = "mdi-file-document";
        break;
    }
    this.cardIcon = iconString;
  },
};
</script>

<style>
.file-name h2 {
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tooltip {
  display: block !important;
  z-index: 10000;
}

.tooltip .tooltip-inner {
  background: black;
  color: white;
  border-radius: 8px;
  padding: 5px 10px 4px;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: black;
  z-index: 1;
}

.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}
.tooltip[aria-hidden="true"] {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s, visibility 0.15s;
}

.tooltip[aria-hidden="false"] {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s;
}
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
</style>
