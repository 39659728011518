<template>
  <v-container fluid>
    <v-row>
      <template v-for="(file, index) in engagementFiles">
        <v-col cols="12" sm="2" :key="index">
          <engagement-file-card
            :created="file.created"
            :download-url="file.downloadUrl"
            :filename="file.filename"
            :id="file.id"
            :mimetype="file.mime"
            :size="file.size"
          ></engagement-file-card>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import EngagementFileCard from "@admin/components/EngagementFileCard.vue";

export default {
  name: "EngagementFilesGrid",
  components: { EngagementFileCard },
  props: {
    engagementFiles: {
      type: Array,
    },
  },
};
</script>

<style scoped></style>
