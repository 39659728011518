<template>
  <v-card-text>
    <div v-if="isLoading">
      <v-progress-linear indeterminate></v-progress-linear>
    </div>
    <div v-else>
      <v-snackbar
        v-model="snackbar"
        :multi-line="true"
        color="warning"
        timeout="-1"
        outlined
        bottom
        right
        transition="slide-x-reverse-transition"
      >
        {{ snackbarError }}
        <template v-slot:action="{ attrs }">
          <v-btn icon color="warning" v-bind="attrs" @click="snackbar = false">
            <v-icon>mdi-close</v-icon>
            <span class="d-none" aria-hidden="true">Close</span>
          </v-btn>
        </template>
      </v-snackbar>
      <v-card class="mx-auto mb-12">
        <section-header :target="() => this.$refs.sponsorContainer"
          ><v-container>
            <v-row>
              <v-col>Sponsor</v-col>
            </v-row>
          </v-container></section-header
        >
        <v-card-text>
          <v-container fluid ref="sponsorContainer">
            <v-row>
              <v-col cols="12" sm="4">
                <dt>Sponsor:</dt>
                <dd v-text="sponsorName"></dd>
              </v-col>
              <v-col cols="12" sm="4">
                <dt>Sponsor Contact:</dt>
                <dd v-text="sponsorContact"></dd>
              </v-col>
              <v-col cols="12" sm="4">
                <dt>RMS Sponsor ID:</dt>
                <dd v-text="rmsSponsorIdentifier"></dd>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>

      <v-card class="mx-auto my-12">
        <section-header :target="() => this.$refs.detailsContainer"
          ><v-container>
            <v-row>
              <v-col>Details</v-col>
            </v-row>
          </v-container></section-header
        >
        <v-card-text>
          <v-container fluid ref="detailsContainer">
            <v-row>
              <v-col cols="12" sm="4">
                <dt>Engagement ID:</dt>
                <dd v-text="identification"></dd>
              </v-col>
              <v-col cols="12" sm="4">
                <dt>Engagement Name:</dt>
                <dd v-text="name"></dd>
              </v-col>
              <v-col cols="12" sm="4">
                <dt>Status:</dt>
                <dd>
                  <v-icon v-if="isActive" color="green"
                    >mdi-check-circle</v-icon
                  >
                  <v-icon v-else color="red">mdi-cancel</v-icon>
                  {{ isActive ? "Active" : "Inactive" }}
                </dd>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="4">
                <dt>Description:</dt>
                <dd v-text="description"></dd>
              </v-col>
              <v-col cols="12" sm="4">
                <dt>Start Date:</dt>
                <dd v-text="startDate"></dd>
              </v-col>
              <v-col cols="12" sm="4">
                <dt>End Date:</dt>
                <dd v-text="endDate"></dd>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="4">
                <dt>Default Radius:</dt>
                <dd v-text="radius"></dd>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>

      <v-card class="mx-auto my-12">
        <section-header :target="() => this.$refs.mediaContainer"
          ><v-container>
            <v-row>
              <v-col>Media</v-col>
            </v-row>
          </v-container></section-header
        >
        <v-card-text>
          <v-container fluid ref="mediaContainer">
            <v-row>
              <v-col cols="12" sm="4">
                <dt>Media Start Date:</dt>
                <dd v-text="mediaLiveDate"></dd>
              </v-col>
              <v-col cols="12" sm="4">
                <dt>Media End Date:</dt>
                <dd v-text="mediaEndDate"></dd>
              </v-col>
              <v-col cols="12" sm="4">
                <dt>G6 URL:</dt>
                <dd v-text="g6Url"></dd>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>

      <v-card class="mx-auto my-12">
        <section-header :target="() => this.$refs.therapiesContainer"
          ><v-container>
            <v-row>
              <v-col>Therapies</v-col>
            </v-row>
          </v-container></section-header
        >
        <v-card-text>
          <v-container fluid ref="therapiesContainer">
            <v-row>
              <v-col cols="12" sm="4">
                <dt>Therapeutic Areas:</dt>
                <dd v-text="therapeuticAreasString"></dd>
              </v-col>
              <v-col cols="12" sm="8">
                <dt>Indications:</dt>
                <dd v-text="indicationsString"></dd>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="4">
                <dt>Rare Disease:</dt>
                <dd>
                  <span>
                    <v-icon v-if="rareDisease" color="green"
                      >mdi-check-circle</v-icon
                    >
                    <v-icon v-else color="red">mdi-cancel</v-icon>
                    {{ rareDisease ? "Yes" : "No" }}
                  </span>
                </dd>
              </v-col>
              <v-col cols="12" sm="4">
                <dt>Cell/Gene Therapy:</dt>
                <dd>
                  <span>
                    <v-icon v-if="cellGeneTherapy" color="green"
                      >mdi-check-circle</v-icon
                    >
                    <v-icon v-else color="red">mdi-cancel</v-icon>
                    {{ cellGeneTherapy ? "Yes" : "No" }}
                  </span>
                </dd>
              </v-col>
              <v-col cols="12" sm="4">
                <dt>Dei Focus:</dt>
                <dd>
                  <span>
                    <v-icon v-if="deiFocus" color="green"
                      >mdi-check-circle</v-icon
                    >
                    <v-icon v-else color="red">mdi-cancel</v-icon>
                    {{ deiFocus ? "Yes" : "No" }}
                  </span>
                </dd>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>

      <v-card class="mx-auto my-12">
        <section-header :target="() => this.$refs.clinicalTrialContainer"
          ><v-container>
            <v-row>
              <v-col> Clinical Trial </v-col>
            </v-row>
          </v-container>
        </section-header>
        <v-card-text>
          <v-container fluid ref="clinicalTrialContainer">
            <v-row>
              <v-col cols="12" sm="4">
                <dt>Protocol ID:</dt>
                <dd v-text="protocolIdentifier"></dd>
              </v-col>
              <v-col cols="12" sm="4">
                <dt>RMS Provider:</dt>
                <dd v-text="rmsProviderName"></dd>
              </v-col>
              <v-col cols="12" sm="4">
                <dt>RMS Engagement ID:</dt>
                <dd v-text="studySubjectIdentifier"></dd>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="4">
                <dt>Phase:</dt>
                <dd v-text="phase.join(', ')"></dd>
              </v-col>
              <v-col cols="12" sm="4">
                <dt>Population:</dt>
                <dd v-text="targetGroupsString"></dd>
              </v-col>
              <v-col cols="12" sm="4">
                <dt>Protocol Version:</dt>
                <dd v-text="protocolVersion"></dd>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="4">
                <dt>Campaign Types:</dt>
                <dd v-text="campaignTypesString"></dd>
              </v-col>
              <v-col cols="12" sm="4">
                <dt>DEI Target:</dt>
                <dd v-text="deiTarget"></dd>
              </v-col>
              <v-col cols="12" sm="4">
                <dt>Target Count:</dt>
                <dd v-text="targetParticipantCount"></dd>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="4">
                <dt>First Patient In Date:</dt>
                <dd v-text="firstPatientInDate"></dd>
              </v-col>
              <v-col cols="12" sm="4">
                <dt>Last Patient In Date:</dt>
                <dd v-text="lastPatientInDate"></dd>
              </v-col>
              <v-col cols="12" sm="4">
                <dt>Amendment Date:</dt>
                <dd v-text="amendmentDate"></dd>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>

      <v-card id="clinical-trial-sites" class="mx-auto my-12">
        <section-header :target="() => this.$refs.clinicalTrialSitesContainer">
          <v-container>
            <v-row>
              <v-col cols="12" sm="4">Clinical Trial Sites</v-col>
              <v-spacer></v-spacer>
              <v-btn
                class="mt-1"
                color="#faaf1c"
                elevation="2"
                :to="{
                  name: 'SiteAssociations',
                  params: { engagementId: engagementId },
                }"
                v-if="showAssociateTrialSitesButton"
              >
                Associate Trial Sites
                <v-icon right>mdi-swap-vertical</v-icon>
              </v-btn>
              <v-btn
                class="mt-1 ml-sm-2"
                color="primary"
                outlined
                elevation="2"
                :href="siteLocationsTemplateUrl()"
                target="_blank"
                v-if="showTrialSitesTemplateButton"
              >
                Trial Sites Template
                <v-icon right>mdi-download</v-icon>
              </v-btn>
              <v-btn
                :to="{
                  name: 'SiteLocationsUpload',
                  params: { engagementId: engagementId },
                }"
                color="primary"
                class="ml-sm-2 mt-1"
                v-if="showUploadTrialSitesButton"
              >
                Upload Trial Sites
                <v-icon right> mdi-cloud-upload </v-icon>
              </v-btn>
            </v-row>
          </v-container>
        </section-header>
        <v-card-text>
          <v-container fluid ref="clinicalTrialSitesContainer">
            <v-row>
              <v-col cols="12">
                <template>
                  <site-locations-table
                    :defaultRadius="radius"
                    :engagementId="engagementId"
                    :isLoading="siteLocationsIsLoading"
                    :siteLocations="siteLocationsTableData"
                    :siteLocationsCount="engagements_site_locations_count"
                    :showRadius="true"
                    @saveRadius="saveEngagementSiteLocationRadius"
                    @saveTrialSiteId="saveEngagementSiteLocationTrialSiteId"
                    @toggleEnabled="toggleEngagementSiteLocationEnabled"
                    @updateSiteLocations="handleSiteLocationsUpdate($event)"
                  >
                  </site-locations-table>
                </template>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
      <v-card class="mx-auto mt-12">
        <section-header :target="() => this.$refs.fileAttachmentsContainer">
          <v-container>
            <v-row>
              <v-col cols="12" sm="8"> File Attachments </v-col>
              <v-spacer></v-spacer>
              <v-btn
                :loading="isUploading"
                :disabled="isUploading"
                color="primary"
                class="ml-sm-2 mt-1"
                @click="$refs.filesUploadField.click()"
                v-if="showUploadFileButton"
              >
                Upload File
                <v-icon right> mdi-cloud-upload </v-icon>
              </v-btn>
              <label for="files-upload" class="d-none" aria-hidden="true"
                >Upload</label
              >
              <input
                type="file"
                id="files-upload"
                ref="filesUploadField"
                @change="doUploadFiles"
                class="d-none"
              />
            </v-row>
          </v-container>
        </section-header>
        <v-card-text>
          <v-container fluid ref="fileAttachmentsContainer">
            <engagement-files-grid
              :engagement-files="engagementsFiles"
            ></engagement-files-grid>
          </v-container>
        </v-card-text>
      </v-card>
    </div>
  </v-card-text>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { getDownloadUrl } from "@utils/http/client";
import { mapActions, mapState, mapGetters } from "vuex";
import EngagementFilesGrid from "@admin/components/EngagementFilesGrid.vue";
import SectionHeader from "@admin/components/SectionHeader.vue";
import SiteLocationsTable from "@admin/components/SiteLocationsTable.vue";

export default {
  name: "EngagementView",
  components: {
    EngagementFilesGrid,
    SectionHeader,
    SiteLocationsTable,
  },
  data() {
    return {
      isLoading: false,
      isUploading: false,
      showUploadResultSnackbar: false,
      siteLocationsIsLoading: false,
      showTrialSitesSnackbar: false,
      snackbar: false,
      snackbarError: null,
    };
  },
  props: {
    engagementId: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapFields("engagements", {
      sponsorId: "engagement.sponsor_id",
      name: "engagement.name",
      description: "engagement.description",
      sponsorContact: "engagement.sponsorContact",
      sponsorName: "engagement.sponsorName",
      targetParticipantCount: "engagement.targetParticipantCount",
      startDate: "engagement.startDate",
      endDate: "engagement.endDate",
      isActive: "engagement.isActive",
      radius: "engagement.radius",
      mediaLiveDate: "engagement.mediaLiveDate",
      mediaEndDate: "engagement.mediaEndDate",
      g6Url: "engagement.g6Url",
      protocolIdentifier: "engagement.protocolIdentifier",
      rmsSponsorIdentifier: "engagement.rmsSponsorIdentifier",
      studySubjectIdentifier: "engagement.studySubjectIdentifier",
      protocolVersion: "engagement.protocolVersion",
      phase: "engagement.phase",
      campaignTypes: "engagement.campaignTypes",
      campaignTypesString: "engagement.campaignTypesString",
      amendmentDate: "engagement.amendmentDate",
      firstPatientInDate: "engagement.firstPatientInDate",
      lastPatientInDate: "engagement.lastPatientInDate",
      targetGroups: "engagement.targetGroups",
      targetGroupsString: "engagement.targetGroupsString",
      rareDisease: "engagement.rareDisease",
      cellGeneTherapy: "engagement.cellGeneTherapy",
      deiFocus: "engagement.deiFocus",
      deiTarget: "engagement.deiTarget",
      identification: "engagement.identification",
      therapeuticAreas: "engagement.therapeuticAreas",
      therapeuticAreasString: "engagement.therapeuticAreasString",
      indications: "engagement.indications",
      indicationsString: "engagement.indicationsString",
      rmsProviderName: "engagement.rmsProviderName",
    }),
    ...mapState("engagements", ["error"]),
    ...mapState("engagementsSiteLocations", [
      "engagements_site_locations_count",
      "uploadResult",
    ]),
    ...mapState("engagementsFiles", ["engagementsFiles", "filesUploadResult"]),
    ...mapGetters("engagementsSiteLocations", ["siteLocationsTableData"]),
    ...mapGetters(["acceptRoles"]),
    showEngagementEditButton() {
      return this.acceptRoles(["Manager", "Admin", "Editor", "Super User"]);
    },
    showEngagementDeleteButton() {
      return this.acceptRoles(["Admin", "Super User"]);
    },
    showAssociateTrialSitesButton() {
      return this.acceptRoles(["Manager", "Admin", "Editor", "Super User"]);
    },
    showTrialSitesTemplateButton() {
      return this.acceptRoles(["Manager", "Admin", "Editor", "Super User"]);
    },
    showUploadTrialSitesButton() {
      return this.acceptRoles(["Manager", "Admin", "Editor", "Super User"]);
    },
    showUploadFileButton() {
      return this.acceptRoles(["Manager", "Admin", "Editor", "Super User"]);
    },
    showDeleteFileButton() {
      return this.acceptRoles(["Manager", "Admin", "Editor", "Super User"]);
    },
  },
  methods: {
    ...mapActions("engagements", [
      "getEngagement",
      "clearEngagement",
      "deleteEngagement",
    ]),
    ...mapActions("engagementsFiles", [
      "clearEngagementsFiles",
      "clearEngagementsFilesUploadResult",
      "getEngagementsFiles",
      "uploadFile",
    ]),
    ...mapActions("engagementsSiteLocations", [
      "getEngagementsSiteLocations",
      "clearEngagementsSiteLocations",
      "updateEngagementSiteLocation",
      "uploadTrialSites",
      "clearEngagementsSiteLocationsUploadResult",
    ]),
    ...mapActions("navigationBar", [
      "clearActionButtons",
      "clearTitleObject",
      "setActionButtons",
      "setBreadcrumbs",
      "setEngagementBreadcrumb",
      "setSponsorBreadcrumb",
      "setTitleObject",
    ]),
    ...mapActions("surveys", [
      "getSurveysForEngagement",
      "clearSurveys",
      "clearSurvey",
      "clearSurveyVersions",
      "addSurvey",
      "exportMetrics",
    ]),
    uploadResultSnackbarColor() {
      if (
        this.uploadResult.total !== undefined &&
        this.uploadResult.total > 0
      ) {
        if (this.uploadResult.failed > 0) {
          if (this.uploadResult.success > 0) {
            // warning
            return "orange darken-4";
          }
          // danger
          return "red darken-4";
        } else if (this.uploadResult.success > 0) {
          // success
          return "green darken-4";
        }
      }
    },
    dismissUploadSnackbar() {
      this.showUploadResultSnackbar = false;
      this.clearEngagementsSiteLocationsUploadResult();
    },
    async doUploadFiles() {
      const fileData = this.$refs.filesUploadField.files[0];
      if (fileData !== undefined) {
        this.isUploading = true;
        const formData = new FormData();
        formData.append("engagement_file", fileData);
        formData.append("engagement_id", this.engagementId);
        await this.uploadFile({
          formData: formData,
          engagementId: this.engagementId,
        }).then((success) => {
          this.$refs.filesUploadField.value = null;
          if (success) {
            this.showUploadResultSnackbar = true;
          }
        });
        this.isUploading = false;
        await this.getEngagementsFiles({
          params: { engagement_id: this.engagementId },
        });
      }
    },
    siteLocationsTemplateUrl() {
      return getDownloadUrl("trial-sites-upload-template.csv");
    },
    saveEngagementSiteLocationRadius({ index, radius }) {
      const engagementSiteLocation =
        this.siteLocationsTableData[index]?.engagementSiteLocation;
      if (engagementSiteLocation !== undefined && radius !== undefined) {
        this.updateEngagementSiteLocation({
          id: engagementSiteLocation.id,
          attributes: {
            radius: radius,
          },
        });
      }
    },
    saveEngagementSiteLocationTrialSiteId({ index, trialSiteId }) {
      const engagementSiteLocation =
        this.siteLocationsTableData[index]?.engagementSiteLocation;
      if (engagementSiteLocation !== undefined && trialSiteId !== undefined) {
        this.updateEngagementSiteLocation({
          id: engagementSiteLocation.id,
          attributes: {
            rmsSiteIdentification: trialSiteId,
          },
        }).then((success) => {
          if (!success) {
            this.snackbarError =
              "Trial Site ID must be unique for this engagement";
            this.snackbar = true;
            this.getEngagementsSiteLocations({
              engagement_id: this.engagementId,
            });
          }
        });
      }
    },
    toggleEngagementSiteLocationEnabled(index) {
      const tableSiteLocation = this.siteLocationsTableData[index];
      const engagementSiteLocation = tableSiteLocation?.engagementSiteLocation;
      if (
        tableSiteLocation !== undefined &&
        engagementSiteLocation !== undefined
      ) {
        this.updateEngagementSiteLocation({
          id: engagementSiteLocation.id,
          attributes: {
            isActive: tableSiteLocation.attributes.isActiveForEngagement,
          },
        });
      }
    },
    async handleSiteLocationsUpdate(options) {
      this.siteLocationsIsLoading = true;
      // account for the siteLocation relationship on each field
      options.sortBy = options.sortBy.map((sortField) => {
        if (sortField[0] === "-") {
          return sortField[0] + "siteLocation." + sortField.slice(1);
        }
        return "siteLocation." + sortField;
      });
      await this.getEngagementsSiteLocations({
        engagement_id: this.engagementId,
        ...options,
      });
      this.siteLocationsIsLoading = false;
    },
    async handleExportMetrics(identity) {
      let response = await this.exportMetrics(identity);

      this.snackbarError = "Survey Metrics could not be exported";
      if (response !== false) {
        this.snackbarError = "Survey Metrics exported!";
      }
      this.snackbar = true;
    },
  },
  async beforeMount() {
    this.isLoading = true;
    await Promise.all([
      this.getEngagement(this.engagementId),
      this.getEngagementsSiteLocations({
        engagement_id: this.engagementId,
      }),
      this.getEngagementsFiles({
        params: { engagement_id: this.engagementId },
      }),
    ]);
    this.setTitleObject(this.name);
    await this.setEngagementBreadcrumb({
      id: this.engagementId,
      name: this.name,
    });
    this.setSponsorBreadcrumb({
      id: this.sponsorId,
      name: this.sponsorName,
    });
    this.setBreadcrumbs();
    let actionButtons = [];
    if (this.showEngagementDeleteButton) {
      actionButtons.push({
        attributes: {
          onConfirm: () => {
            this.deleteEngagement(this.engagementId).then(([success]) => {
              if (success) {
                this.$router.push({
                  name: "EngagementsIndex",
                  params: {},
                });
              } else {
                this.snackbarError =
                  "Surveys must be deleted before an Engagement is deleted.";
                this.snackbar = true;
              }
            });
          },
        },
        component: "DeleteButton",
        index: 0,
      });
    }
    if (this.showEngagementEditButton) {
      actionButtons.push({
        attributes: {
          to: "/engagements/edit/:id".replace(":id", this.engagementId),
        },
        component: "EditButton",
        index: 1,
      });
    }
    this.setActionButtons(actionButtons);
    this.isLoading = false;
  },
  destroyed() {
    this.clearActionButtons();
    this.clearEngagement();
    this.clearEngagementsSiteLocations();
    this.clearTitleObject();
  },
};
</script>

<style scoped>
dt {
  font-weight: bold;
  font-size: 1.1rem;
}
</style>
